<!-- Localized -->
<template>
  <div
    :class="[
      'formulate-input-element',
      {
        [`formulate-input-element--${context.class}`]: context.class,
      },
    ]"
  >
    <div
      class="editor field th-full tw-relative"
      :class="{
        'editor--disabled': disabled,
        'editor--invalid field--invalid': invalid,
      }"
      :style="styles"
    >
      <!-- <div class="tw-hidden">
                <font-awesome-icon ref="insertTable" icon="table" size="1x" class="tw-cursor-pointer" />
            </div> -->
      <div class="qeditor-wrapper" >
        <quill-editor
          :disabled="disabled || this.$route.params.action == 'view'"
          :id="editorId"
          v-model="context.model"
          ref="myQuillEditor"
          :options="editorConfig"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
          v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
// TODO optimize component: remove extra functions

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor , Quill } from "vue-quill-editor";
// import { ImageExtend } from "quill-image-extend-module";

// import axios from "@/axios";
// import QuillBetterTable from "quill-better-table";

// Quill.register("modules/ImageExtend", ImageExtend);

// Quill.register(
//     {
//         "modules/better-table": QuillBetterTable,
//     },
//     true
// );

// configure Quill to use inline styles so the email's format properly
var DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute,true);

var AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass,true);

var BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass,true);

var ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass,true);

var DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass,true);

var FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass,true);

var SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass,true);

var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle,true);

var BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle,true);

var ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle,true);

var DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle,true);

var FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle,true);

var sizes = ['10px','13px','16px','24px']
var SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = sizes;
Quill.register(SizeStyle,true);

const Parchment = Quill.import('parchment');

class IndentAttributor extends Parchment.Attributor.Style {
  add (node, value) {
    if (value === 0) {
      this.remove(node)
      return true
    } else {
      return super.add(node, `${value}em`)
    }
  }
}

let IndentStyle = new IndentAttributor('indent', 'text-indent', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em']
})

Quill.register(IndentStyle, true)

export default {
  name: "rich-text-editor",
  components: {
    quillEditor,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    // value: {
    //     type: String,
    //     default: () => "",
    // },
    placeholder: {
      type: String,
      default: () => "Enter description here",
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    invalid: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      content: "",
      uploadedUrl: null,
      table: null,
      editorOption: {
        placeholder: this.context.attributes.placeholder,
        modules: {
          toolbar: {
            container: [
              [
                { size: sizes },
                { header: [1, 2, 3, 4, 5, 6, false] },
              ],
              [
                "bold",
                "italic",
                "underline",
                "strike",
                { color: [] },
                { background: [] },
              ],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              [
                { 'align': [] },
              ],
              ["link" /* , "image" */],
              ["clean"] /* , ["insertTable"] */,
            ],
            handlers: {
              // image: this.selectLocalImage,
              // insertTable: () => this.table.insertTable(3, 3),
              // image: function() {
              //   QuillWatch.emit(this.quill.id);
              // }
            },
          },
        },
      },
      onEditorBlur() {
        this.context.blurHandler();
      },
      onEditorFocus() {},
      onEditorReady(editor) {
        if (!window.editor) window.editor = {};
        window.editor[this.name] = editor;
        // this.table = window.editor[this.name].getModule("better-table");
        // editor.clipboard.dangerouslyPasteHTML(this.context.model, "silent");
        editor.setContents(editor.clipboard.convert(this.context.model));

        const label = document.querySelector(`[for=${this.context.id}]`);
        label.addEventListener('click', () => {
          editor.focus();
        })

        // TODO should be optimized
        // const toolbar = editor.getModule("toolbar").container;
        // const insertTableBtn = toolbar.querySelector(".ql-insertTable");
        // const btnIcon = this.$refs.insertTable.cloneNode(true);
        // insertTableBtn.appendChild(btnIcon);
      },
    };
  },
  computed: {
    // unique name for creating editor instance
    name() {
      return this.context.id;
    },
    // * used for value changes handling because of errors in quill props handlers
    contentComputed: {
      get() {
        return this.content;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    editor() {
      return this.$refs.myQuillEditor;
    },
    editorId() {
      return `ql-editor--${this.name}`;
    },
    editorConfig() {
      return {
        ...this.editorOption,
        bounds: "#" + this.editorId,
      };
    },
  },
  methods: {
    refreshValue(newValue) {
      window.editor[this.name].clipboard.dangerouslyPasteHTML(newValue);
    },
    // async selectLocalImage() {
    //     const input = document.createElement("input");
    //     input.setAttribute("type", "file");
    //     input.click();

    //     // Listen upload local image and save to server
    //     input.onchange = async () => {
    //         const file = input.files[0];

    //         // file type is only image.
    //         if (/^image\//.test(file.type)) {
    //             await this.saveToServer(file);
    //         } else {
    //             console.warn("You could only upload images.");
    //         }
    //     };
    // },

    // /**
    //  * Step2. save to server
    //  *
    //  * @param {File} file
    //  */
    // async saveToServer(file) {
    //     const fd = new FormData();
    //     fd.append("file", file);

    //     try {
    //         const url = `/uploads/reports/images`;
    //         const res = await axios.post(url, fd, {
    //             headers: { "Content-Type": "multipart/form-data" },
    //         });
    //         this.uploadedUrl = `/images/${res.data.file}`;
    //         this.insertToEditor();
    //     } catch (error) {}
    // },

    // /**
    //  * Insert image url to rich editor.
    //  *
    //  * @param {string} url
    //  */
    // insertToEditor(url) {
    //     // push image url to rich editor.

    //     const range = window.editor[this.name].getSelection();
    //     window.editor[this.name].editor.insertEmbed(range.index, "image", this.uploadedUrl);
    // },
    insert(link) {
      var selection = window.editor[this.name].getSelection(true);
      if (selection.length >= 1) {
        // var text = window.editor[this.name].getText(selection.index, selection.length);
        window.editor[this.name].format("link", link);
      } else {
        window.editor[this.name].insertText(selection.index, link);
      }
      // this.texts.forEach((e) => {
      // var selection = window.editor[this.name].getSelection(true);
      // });
    },
    refreshData() {
      // TODO
      console.log("refresh links");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// @import "../../mixins.scss";
// * {
//     @include scrollBar;
//     &::-webkit-scrollbar {
//         cursor: default;
//     }
// }
.formulate-input-element {
  max-width: unset;
}
.editor {
  border-radius: 0.3em;
  border: 1px solid #cecece;
  min-height: 350px;
  &--disabled {
    cursor: not-allowed;
    opacity: 0.6;
    * {
      pointer-events: none;
    }
  }
  // @include field;
  padding: 0 !important;
  box-sizing: border-box;
  .toolbar-additional {
    right: 0;
    margin-left: auto;
    svg {
      &:hover {
        color: var(--brand-color);
      }
    }
  }
  height: 100%;
  display: flex;
  flex-direction: column;
}
.qlbt-operation-menu {
  z-index: 33;
}
.insert-btn {
  display: flex;
  align-items: center;
  border: 1px dashed var(--brand-color);
  border-radius: 5rem;
  padding: 0.25rem 1rem !important;
  transition: var(--transition-mnml);
  cursor: pointer;
  outline: none;
  background-color: transparent;

  &:hover {
    background-color: var(--brand-transparent);
  }

  p {
    margin: 0 0.6rem;
    color: var(--brand-color);
    font-size: 14px;
  }
  img {
    background-color: #bed4fc;
    padding: 0.3rem;
    border-radius: 100%;
    margin: 0.1rem;
  }
}
</style>
<style lang="scss">
.qeditor-wrapper {
  height: 100%;
}
.ql-container.ql-snow {
  overflow: hidden;
}
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-width: 0;
}
.ql-toolbar.ql-snow {
  border-bottom-width: 1px;
}
.multiselect {
  &__option {
    &--highlight {
      color: black;
    }
  }
}

.ql-snow .ql-size .ql-picker-item[data-value="24px"]::before {
  content: "Huge";
  font-size: xx-large !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="16px"]::before {
  content: "Large";
  font-size: 16px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="10px"]::before {
  content: "Small";
  font-size: 10px !important;
}
.ql-snow .ql-size .ql-picker-label[data-value="24px"]::before {
  content: "Huge";
}
.ql-snow .ql-size .ql-picker-label[data-value="16px"]::before {
  content: "Large";
}
.ql-snow .ql-size .ql-picker-label[data-value="10px"]::before {
  content: "Small";
}
.ql-snow .ql-editor li{
  list-style-type: disc;
}
.ql-snow .ql-editor ol li {
  list-style-type: decimal;
}
</style>
