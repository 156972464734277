<!-- Localized -->
<template>
    <div class="color-picker  border border-solid border-gray-300 bg- bg-white shadow rounded-lg " :class="{ 'h-10 py-1': !isOpen}">
      <div
        class="color-picker__header flex items-center gap-2 justify-between cursor-pointer pl-3 pr-1 py-1"
        :class="{
          'border-b border-solid border-gray-300': isOpen,
          'cursor-not-allowed': disabled,
        }"
      >
        <!-- <span class="color-picker__name text-base-content text-xs font-semibold">{{ name }}</span> -->
        <div v-if="currentHex" class="color-picker__value flex items-center gap-1" @click="isOpen = true">
          <div class="value__text px-2 rounded bg-gray-100 uppercase text-base-content text-xs font-bold h-5 flex items-center justify-center w-30 min-w-min">
            {{ currentHex }}
          </div>
          <div
            class="value__color   rounded  border  border-gray-400  w-5 h-5"
            :style="{'backgroundColor': getBackgroundColor}"
          ></div>
        </div>
      </div>
      <transition name="fade" mode="out-in">
  
      <div
        v-show="isOpen"
        class="color-picker__picker px-3 py-4 flex flex-col gap-3"
        :class="{'pointer-events-none': disabled || readonly}"
      >
        <ColorPicker
          v-model="modelValue"
        />
        <div class="color-picker__controls flex justify-between gap-1">
          <Button
            class="w-24 focus:bg-blue-600"
            :text="$t('actions.cancel')"
            size="xs"
            height="7"
            @click="resetColor"
          />
          <Button
            class="w-24 bg-blue-500 border-blue-500 focus:bg-blue-600 active:bg-blue-600  hover:bg-blue-600 hover:border-blue-600"
            :text="$t('actions.apply')"
            type="secondary"
            size="xs"
            height="7"
            @click="saveColor"
          />
        </div>
      </div>
      </transition>
    </div>
  </template>
  
  <script>
  import { Chrome } from 'vue-color';
  import Button from "@/components/button";
  
  export default {
    props: {
      // value: String,
      name: String,
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      // defaultColor: {
      //   type: String,
      //   default: '#FFFFFF',
      // },
    //   isOpen: {
    //     type: Boolean,
    //     default: false,
    //   }
    },
    components: {
      ColorPicker: Chrome,
      Button,
    },
    data() {
      return {
        value: "#0D68D3",
        modelValue: "#0D68D3",
        defaultColor:'#0D68D3',
        currentHex: '#0D68D3',
        isOpen: false,
      }
    },
    created() {
      this.resetColor();
    },
    watch: {
      value(newValue) {
        this.modelValue = newValue || this.defaultColor;
        this.currentHex = this.modelValue;
      },
    },
    computed: {
      getBackgroundColor() {
        return this.currentHex;
      }
    },
    methods: {
      saveColor() {
        if (this.modelValue.hex) {
          this.currentHex = this.modelValue.a === 1 ? this.modelValue.hex : this.modelValue.hex8;
        } else {
          this.currentHex = this.modelValue;
        }
        this.$emit('input', this.currentHex);
        this.$emit('change', this.currentHex);
        this.isOpen = false;
      },
      resetColor() {
        this.modelValue = this.value || this.defaultColor;
        this.currentHex = this.modelValue;
        this.$emit('input', this.currentHex);
        this.$emit('change', this.currentHex);
        this.isOpen = false;
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  ::v-deep {
    .vc-chrome {
      @apply flex flex-col items-stretch gap-3;
      box-shadow: none;
      width: 100%;
      .vc-chrome-saturation-wrap {
        @apply rounded-lg p-0;
        height: 60px;
      }
      .vc-chrome-body {
        @apply p-0 flex flex-col gap-3;
        .vc-chrome-controls {
          @apply flex gap-3 items-center;
          .vc-chrome-color-wrap {
            order: 2;
            width: initial;
            .vc-chrome-active-color {
              @apply rounded-lg border border-solid border-gray-200;
              margin: 0;
            }
          }
          .vc-chrome-sliders {
            order: 1;
          }
        }
        .vc-chrome-fields-wrap {
          @apply p-0;
          .vc-chrome-fields {
            .vc-chrome-field {
              @apply p-0;
              .vc-input__input {
                @apply border border-solid border-gray-300 rounded-lg font-semibold px-3 text-left text-sm h-7 text-gray-800 font-mono ;
              }
              .vc-input__label {
                display: none;
              }
            }
          }
          .vc-chrome-toggle-btn {
            display: none;
          }
        }
      }
    }
  }
  </style>