import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {
    getMessages,
    loadLanguageAsync as baseLoadLanguageAsync,
} from './utils/index.js';
import { defaultLang } from "./utils/constants";
export { defaultLang };

Vue.use(VueI18n)

// TODO use cached lang from localStorage instead:
// uncomment and use if need to use i18n before login:
// const cachedLang = localStorage.getItem("lang");
// const initialLang = cachedLang ?? defaultLang;
const initialLang = defaultLang;

// is used for axios requests before auth and fetching of user's lang preferences:
localStorage.setItem("lang", initialLang);

// Load only choosen translation initially:
let messages = {}

// next logic consider cached lang. May be simplified if there is no need to check localStorage initially
const initLanguage = (lang) => {
    try {
        const currentMessages = getMessages(initialLang);
        messages[initialLang] = currentMessages;
    } catch (error) {
        console.error('Error loading language:', error);

        const langCodeParts = lang.split("-");
        if (langCodeParts > 1) {
            initLanguage(langCodeParts[0]);
        } else {
            const currentMessages = getMessages(defaultLang);
            messages[defaultLang] = currentMessages;
        }
    }
}
initLanguage(initialLang);

const i18n = new VueI18n({
    locale: initialLang, // initial language
    fallbackLocale: defaultLang,
    messages
})

export const loadLanguageAsync = async (lang) => {
    await baseLoadLanguageAsync(lang, i18n, defaultLang);
}

export default i18n;