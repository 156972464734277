import {
  defaultLang,
  languageToCountryMap,
  rtlLanguages,
} from "./constants"

/**
 * Recursively builds the messages object for a specific language
 * @param {Object} context Webpack require.context
 * @param {string} locale Target language (e.g., "en", "ru")
 * @returns {Object} Message tree for the given language
 */
function buildMessages(context, locale) {
  const messages = {};

  context.keys().forEach((key) => {
    try {
      const pathParts = key.replace('./', '').split('/');
      const fileLocale = pathParts.shift(); // Extract locale from path

      if (fileLocale !== locale) return; // Ignore files for other languages

      let currentLevel = messages;

      // Traverse remaining path parts and create nested objects
      for (let i = 0; i < pathParts.length; i++) {
        const part = pathParts[i];
        const isFile = part.endsWith('.json');
        const keyName = isFile ? part.replace('.json', '') : part;

        if (!currentLevel[keyName]) {
          if (isFile) {
            try {
              currentLevel[keyName] = context(key) // Load JSON synchronously
            } catch (error) {
              console.error(`Failed to load translation file: ${key}`, error)
            }
          } else {
            currentLevel[keyName] = {};
          }
        }

        if (!isFile) {
          currentLevel = currentLevel[keyName];
        }
      }
    } catch (error) {
      console.error(`Error processing translation file: ${key}`, error);
    }
  });

  if (Object.keys(messages).length === 0) {
    throw new Error(`No translation files found for locale "${locale}"`);
  }

  return messages;
}

const requireContext = require.context('../locales', true, /\.json$/)

// Dynamically loads language translations
export async function loadLanguageAsync(lang, i18n, fallbackLang, retry = false) {
  if (!retry) {
    localStorage.setItem("lang", lang);
  }

  if (i18n.messages[lang]) {
    i18n.locale = lang;
    return;
  }

  try {
    const newMessages = buildMessages(requireContext, lang);

    if (Object.keys(newMessages).length === 0) {
      throw new Error(`Language ${lang} has no valid translation files`);
    }

    i18n.setLocaleMessage(lang, newMessages);
    i18n.locale = lang;
    console.log("Language changed to:", lang);
  } catch (error) {
    console.error(`Error loading language ${lang}:`, error);
    const langCodeParts = lang.split("-");
    if (langCodeParts > 1) {
      await loadLanguageAsync(langCodeParts[0], i18n, fallbackLang, true);
    } else {
      i18n.locale = fallbackLang;
    }
  }
}


export function getMessages(lang) {
  return buildMessages(requireContext, lang);
}

export function getCountryCodeByLanguage(langCode) {
  return languageToCountryMap[langCode.split("-")[0]] || null;
}

export function isRTL(langCode) {
  return rtlLanguages.includes(langCode);
}

export const setDocumentDir = (isRTL = false) => {
  document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr"); // Update global `dir` attribute
}

export const setDocumentLang = (lang = defaultLang) => {
  document.documentElement.setAttribute("lang", lang); // Update global `lang` attribute
}
