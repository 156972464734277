var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',_vm._g(_vm._b({staticClass:"previewFormModal",class:{'consentModal':_vm.isConsentModal, 'cadidateFormPreview' : _vm.title == 'Form Preview'},attrs:{"name":_vm.modalName,"width":_vm.width,"maxWidth":_vm.maxWidth,"height":_vm.height,"scrollable":"","adaptive":"","clickToClose":_vm.clickToClose},on:{"closed":_vm.hideModal,"before-open":function($event){return _vm.$emit('beforeOpen')},"before-close":function($event){return _vm.$emit('beforeClose')}}},'modal',{..._vm.$attrs, ..._vm.settings},false),_vm.$listeners),[_c('div',{staticClass:"modalContent flex flex-col h-full relative text-base-content",class:{
            'modalContent--loading': _vm.loading || _vm.loadingGlobal,
            'createModal' : _vm.title == 'Create Case',
            'restModal' : (_vm.title != 'Create Case' && _vm.title != 'Form Preview'),
        }},[(_vm.loadingGlobal)?_c('div',{staticClass:"globalLoader"},[_c('loader',{attrs:{"loading":true,"success":_vm.success,"size":16}})],1):_vm._e(),(_vm.showHeader)?_c('div',{staticClass:"modalContent__header py-2 px-6 flex items-center justify-between min-h-8"},[(_vm.title)?_c('h3',{staticClass:"text-white-text font-semibold title-check text-lg flex items-center gap-2"},[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]})],2):_vm._e(),(_vm.showClose)?_c('span',{staticClass:"cursor-pointer text-white-text cross ml-auto",on:{"click":function($event){$event.stopPropagation();return _vm.onCrossClick.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":"times","size":"lg"}})],1):_vm._e()]):_vm._e(),(_vm.$slots.content)?_c('div',{staticClass:"text-base-content modalContent__content flex-grow border-solid bg-card-bg p-5 xl:p-6 border-0 border-t border-gray-300",class:[
                {'bg-gray-100': _vm.darkContent}, 
                _vm.contentScrollable ? 'overflow-y-auto' : 'flex h-full overflow-hidden'
            ]},[_vm._t("content",function(){return [_vm._v("Modal content")]})],2):_vm._e(),_vm._t("default"),(_vm.showFooter)?_c('div',{staticClass:"modalContent__footer p-4 pt-0 lg:p-6 lg:pt-0 flex justify-end bg-card-bg"},[_vm._t("footer",function(){return [(_vm.additionalBtn)?_c('button',{staticClass:"btn btn-outline btn-info bottom-8 right-8 w-auto mr-2.5",attrs:{"bg":_vm.loading ? 'var(--brand-accent-neutral-alt)' : 'var(--brand-color)',"color":"white","hoverBg":"var(--brand-hover-on)","hoverText":"white","padding":"0.4rem 0.7rem","borderRadius":"0.3rem"},domProps:{"textContent":_vm._s(_vm.additionalBtn)},on:{"click":function($event){return _vm.$emit('alt-btn')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('alt-btn')}}}):_vm._e(),_c('Button',{staticClass:"btn bottom-8 right-8 w-auto",attrs:{"disabled":_vm.disabled || _vm.loading || _vm.loadingGlobal,"bg":_vm.loading || _vm.loadingGlobal ? 'var(--brand-accent-neutral-alt)' : 'var(--brand-color)',"type":"primary","loading":_vm.loading,"text":_vm.submitButtonText},on:{"click":_vm.submitModal,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitModal.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.submitButtonText)+" ")])]})],2):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }