export const defaultLang = "en";

// maps ISO 639-1 language codes to corresponding ISO 3166-1 alpha-2 country codes:
export const languageToCountryMap = {
  af: "za", // Afrikaans → South Africa
  ar: "sa", // Arabic → Saudi Arabia
  az: "az", // Azerbaijani → Azerbaijan
  be: "by", // Belarusian → Belarus
  bg: "bg", // Bulgarian → Bulgaria
  bs: "ba", // Bosnian → Bosnia and Herzegovina
  ca: "es", // Catalan → Spain
  cs: "cz", // Czech → Czech Republic
  cy: "gb", // Welsh → United Kingdom
  da: "dk", // Danish → Denmark
  de: "de", // German → Germany
  dv: "mv", // Dhivehi → Maldives
  el: "gr", // Greek → Greece
  en: "us", // English → United States
  eo: null, // Esperanto → International (no specific country)
  es: "es", // Spanish → Spain
  et: "ee", // Estonian → Estonia
  eu: "es", // Basque → Spain
  fa: "ir", // Persian → Iran
  fi: "fi", // Finnish → Finland
  fo: "fo", // Faroese → Faroe Islands
  fr: "fr", // French → France
  ga: "ie", // Irish → Ireland
  gl: "es", // Galician → Spain
  gu: "in", // Gujarati → India
  he: "il", // Hebrew → Israel
  hi: "in", // Hindi → India
  hr: "hr", // Croatian → Croatia
  hu: "hu", // Hungarian → Hungary
  hy: "am", // Armenian → Armenia
  id: "id", // Indonesian → Indonesia
  is: "is", // Icelandic → Iceland
  it: "it", // Italian → Italy
  ja: "jp", // Japanese → Japan
  ka: "ge", // Georgian → Georgia
  kk: "kz", // Kazakh → Kazakhstan
  kn: "in", // Kannada → India
  ko: "kr", // Korean → South Korea
  kok: "in", // Konkani → India
  ky: "kg", // Kyrgyz → Kyrgyzstan
  lt: "lt", // Lithuanian → Lithuania
  lv: "lv", // Latvian → Latvia
  mi: "nz", // Maori → New Zealand
  mk: "mk", // Macedonian → North Macedonia
  mn: "mn", // Mongolian → Mongolia
  mr: "in", // Marathi → India
  ms: "my", // Malay → Malaysia
  mt: "mt", // Maltese → Malta
  nb: "no", // Norwegian (Bokmål) → Norway
  nl: "nl", // Dutch → Netherlands
  nn: "no", // Norwegian (Nynorsk) → Norway
  ns: "za", // Northern Sotho → South Africa
  pa: "in", // Punjabi → India
  pl: "pl", // Polish → Poland
  ps: "af", // Pashto → Afghanistan
  pt: "pt", // Portuguese → Portugal
  qu: "pe", // Quechua → Peru
  ro: "ro", // Romanian → Romania
  ru: "ru", // Russian → Russia
  sa: "in", // Sanskrit → India
  se: "no", // Sami → Norway
  sk: "sk", // Slovak → Slovakia
  sl: "si", // Slovenian → Slovenia
  sq: "al", // Albanian → Albania
  sr: "rs", // Serbian → Serbia
  sv: "se", // Swedish → Sweden
  sw: "ke", // Swahili → Kenya
  syr: "sy", // Syriac → Syria
  ta: "in", // Tamil → India
  te: "in", // Telugu → India
  th: "th", // Thai → Thailand
  tl: "ph", // Tagalog → Philippines
  tn: "za", // Tswana → South Africa
  tr: "tr", // Turkish → Turkey
  tt: "ru", // Tatar → Russia
  ts: "za", // Tsonga → South Africa
  uk: "ua", // Ukrainian → Ukraine
  ur: "pk", // Urdu → Pakistan
  uz: "uz", // Uzbek → Uzbekistan
  vi: "vn", // Vietnamese → Vietnam
  xh: "za", // Xhosa → South Africa
  zh: "cn", // Chinese → China
  zu: "za", // Zulu → South Africa
};

export const rtlLanguages = [
  "ar",    // Arabic
  "ar-AE", // Arabic (U.A.E.)
  "ar-BH", // Arabic (Bahrain)
  "ar-DZ", // Arabic (Algeria)
  "ar-EG", // Arabic (Egypt)
  "ar-IQ", // Arabic (Iraq)
  "ar-JO", // Arabic (Jordan)
  "ar-KW", // Arabic (Kuwait)
  "ar-LB", // Arabic (Lebanon)
  "ar-LY", // Arabic (Libya)
  "ar-MA", // Arabic (Morocco)
  "ar-OM", // Arabic (Oman)
  "ar-QA", // Arabic (Qatar)
  "ar-SA", // Arabic (Saudi Arabia)
  "ar-SY", // Arabic (Syria)
  "ar-TN", // Arabic (Tunisia)
  "ar-YE", // Arabic (Yemen)
  "fa",    // Persian (Farsi)
  "fa-IR", // Persian (Iran)
  "he",    // Hebrew
  "he-IL", // Hebrew (Israel)
  "ur",    // Urdu
  "ur-PK", // Urdu (Pakistan)
  "dv",    // Dhivehi
  "dv-MV", // Dhivehi (Maldives)
  "ps",    // Pashto
  "ps-AR", // Pashto (Afghanistan)
  "syr",   // Syriac
  "syr-SY" // Syriac (Syria)
];
