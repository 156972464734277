<!-- Localized -->
<template>
    <div class="single-select-with-actions multiselect-custom h-7 2xl:h-9 rounded-lg 2xl:rounded-xl text-sm text-base-content">
    <VueMultiselect
        v-model="value"
        @input="selectOptionActions"
        :options="options" 
        :multiple="false"
        :close-on-select="false"
        v-bind="{ ...computedConfig, ...$attrs }"
        ref="multiselect"
        @tag="addTag"

    >
        <template #singleLabel>
            <div class="flex align-center gap-2">
                <span>{{ value?.name }}</span>
                <template v-if="value?.actions?.length > 0">
                    <span
                        v-for="action in value?.actions"
                        :key="`${action}-icon-selected`"
                        class="mt-0.5"
                    >
                        <component :is="getIconComponent(action)" />
                    </span>
                </template>
            </div>
        </template>

        <template #caret="{ toggle }">
            <div
               class="caret caret--rotable right-2 2xl:right-1.5"
               :class="disabled ? 'tw-hidden' : ''"
               @mousedown.prevent.stop="toggle()"
            >
                <dropdown-icon />
            </div>
        </template>

        <template #beforeList>
            <div class="custom-label custom-label--before-list flex justify-between items-center gap-1 py-2 px-4">
                <span>{{ listLabel }}</span>
                <div class="flex justify-between gap-5 mr-1">
                    <span
                        v-for="action in actions"
                        :key="`${action.value}-icon`"
                    >
                        <component :is="action.component" />
                    </span>
                </div>
            </div>
        </template>

        <template #option="props">
            <div class="flex justify-between w-full">
                <span>{{ props.option.name }}</span>
                <div class="flex justify-between gap-4" >
                    <label
                        v-for="action in actions"
                        :key="`${action.value + props.option.id}`"
                    >
                        <input
                            type="checkbox"
                            :value="action.value"
                            v-model="selectedActions[props.option.id]"
                            :disabled="isCheckboxDisabled(props.option, action)"
                            class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-md pointer-events-none"
                            @click.stop="onCheckboxClick(action)"
                        />
                    </label>
                </div>
            </div>
        </template>
    </VueMultiselect>
    </div>
</template>
<script>
import VueMultiselect from "vue-multiselect";
import dropdownIcon from "@shared/assets/icons/dropdown.svg";
import { isEqual } from "lodash";

export default {
    name: "SingleSelectWithActions",

    components: {
        VueMultiselect,
        dropdownIcon
    },

    props: {
        context: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            selectedActions: {},
        }
    },

    watch: {
        selectedActions: {
            handler(val) {
                this.onCheckboxCheck(val);
            },
            deep:true,
        },
        'context.options': {
            immediate: true,
            deep: true,
            handler() {
                this.setDefaultActions();
            },
        },
    },

    computed: {
        computedConfig() {
            return {
                searchable: false,
                selectLabel: "",
                deselectLabel: "",
                selectedLabel: null,
                label: "name",
                trackBy: "id",
                allowEmpty: false,
                ...(this.context?.attributes || {})
            };
        },

        value: {
            get() {
                return this.context.model;
            },
            set(val) {
                this.context.model = val;
            },
        },

        options() {
            return this.context.options;
        },

        actions() {
            return this.context.attributes.actions;
        },

        listLabel() {
            return this.context.attributes["list-label"];
        },

        disabled() {
            return this.context.attributes.disabled;
        },
    },

    methods: {
        addTag (newTag) {
            const addTagHandler = this.context?.attributes?.addTag;
            if (typeof addTagHandler == 'function') {
                addTagHandler(newTag);
            }
        },
        onCheckboxClick(action) {
            // select multiple checkboxes at the same time
            if (action.selectAll) {
                this.selectedActions[this.value?.id] = this.actions.map(a => a.value);
            }

            // prevent dropdown from closing after checkbox check
            this.$refs.multiselect.activate();
        },

        isCheckboxDisabled(option, action) {
            if (this.value?.id !== option.id) return true;
            return this.needDisableOtherCheckboxes(action);
        },

        needDisableOtherCheckboxes(action) {
            const selectedActions = this.selectedActions[this.value?.id] ?? [];
            let needSelectAll = null;

            for (const item of selectedActions) {
                needSelectAll = this.actions.find(a => a.value === item)?.selectAll;
                if (needSelectAll) break;
            }

            return needSelectAll && !action.selectAll;
        },

        setDefaultActions() {
            for (const option of this.options) {
                const defaultActions = this.value?.id === option.id
                    ? this.value?.actions ?? []
                    : [];
                this.$set(this.selectedActions, option.id, defaultActions);
            }
       },

        onCheckboxCheck(val) {
            if (!this.value) return;

            let actionsToSet = null;
            const selectedActions = val[this.value.id];

            for (const action in selectedActions) {
                const needSelectAll = this.actions.find(a => a.value === action)?.selectAll;

                if (needSelectAll) {
                    actionsToSet = this.actions.map(a => a.value);
                    break;
                }
            }

            const payload = {
                ...this.value,
                actions: actionsToSet?.length > 0 ? actionsToSet : selectedActions,
            };

            if (isEqual(this.value, payload)) return;
            this.value = payload;
        },

        getIconComponent(action) {
            return this.actions?.find(a => a.value === action)?.component;
        },

        selectOptionActions(option) {
            if (!option) return;
            // select all actions by default:
            const actionList = this.actions.map(a => a.value);
            this.selectedActions[option.id] = actionList;
        },
    }
}
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@import "@/assets/styles/functions.scss";
@import "../mixins.scss";

.single-select-with-actions {
    @include customMultiSelect;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    height: var(--input-height);

    min-width: 114px;
    min-height: 24px;
    border-width: 1px;
    border-style: solid;
    border-radius: var(--border-radius);

    cursor: pointer;

    &__checkbox {
        div {
            display: flex;
            gap: 20px;
       }
    }

    .caret {
        display: inline-flex;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        // right: 14px;
        padding: 5px 1px;
        z-index: 1;
        &.tw-hidden {
            display: none;
        }
        svg {
            // width: 12px;
            transition: transform 0.2s;
            @apply fill-current;
        }
    }
}
</style>