<!-- Localized -->
<template>
  <button
  type="button"
    class="btn normal-case px-4 leading-tight h-auto min-h-0 py-2 gap-2 flex items-center focus:bg-blue-700 rounded-lg"
    :class="getStyles()"
    @click="handleClick()"
    :disabled="disabled"
  >
	<template v-if="icon">
		<font-awesome-icon
      :class="[iconClasses || 'text-lg', {
        '-ml-2 mr-2': alignIcon,
      }]"
      :icon="icon"
    />
	</template>
  <slot />
	<span
    v-if="!$slots.default"
    class="capitalize font-bold  tracking-wide"
    v-text="text"
  />
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: " ",
    },
    type: {
      type: String,
      default: " ",
    },
    rounded: {
      default: false,
      type: Boolean,
    },
    block: {
      default: false,
      type: Boolean,
    },
    outline: {
      default: false,
      type: Boolean,
    },
    size: {
      default: " ",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    cirlce: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: "",
      type: String,
    },
    loader: {
      default: false,
      type: Boolean,
    },
    alignIcon: {
      type: Boolean,
      default: true,
    },
    iconClasses: {
      type: [Array, Object, String],
      default: '',
    },
  },
  methods: {
    getStyles() {
      let classes = " ";
      if (this.type) classes += ` btn-${this.type} `;
      if (this.outline) classes += ` btn-outline border-2 `;
      if (this.size) {
        classes += ` btn-${this.size} `;
		if (this.size === 'sm') classes +=  '  py-2 '
      }
      if (this.cirlce) classes += ` btn-circle `;
      if (this.loader) classes += " loading opacity-50";
      if (this.disabled) classes += " bg-gray-300 ";
      return classes;
    },
    handleClick() {
      if (this.disabled) return;
      this.$emit("click");
    },
  },
};
</script>
<style scoped>  
</style>
