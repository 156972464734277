<!-- Localized -->
<template>
    <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
        <VuePhoneNumberInput v-model="context.model" clearable  @update="onUpdate" :error="results && !results.isValid" />
        <!-- {{results}}
        <br />
        {{context}} -->
    </div>
    
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    name: "phone-field",
    components:{
        VuePhoneNumberInput,
    },
    props:{
        context: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },
    data(){
        return {
            phoneNumber: null,
            results: null             

            
        }
    },
    methods: {
        onUpdate (payload) {
            this.results = payload
        }
    }
}
</script>

<style>

</style>