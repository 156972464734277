<!-- Localized -->
<template>
  <div class="flex-shrink-0 font-medium leading-5 text-base mb-4 text-gray-900  pt-6  pr-2 ">
      {{getLabel}}
  </div>
</template>

<script>
export default {
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getLabel: {
            get() {
                return this.context.label || " ";
            },
            // set(val) {
            //     this.context.model = val;
            // },
        },
        // isDisabled() {
        //     return this.context?.attributes?.disabled ?? false;
        // },
    },
}
</script>

<style>

</style>