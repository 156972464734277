<!-- Localized -->
<template>
  <div class="flex flex-col max-w-full">
       <div
            class="my-2  space-y-3  overflow-y-auto scroll-bar"
            style="max-height: 20rem"
            v-if="getValue && getValue.length"
          >

           <div  v-for="file in getValue"
            class="flex transition-colors
                hover:bg-blue-100 items-center
                p-2.5 bg-blue-50  rounded-xl last:mb-5"
              :key="file.id">
            <a
              :title="`Download ${file.filename}`"
              download
              target="_blank"
              :href="file.link"
              class="
                gap-x-2
                truncate
                flex items-center
              "
            >
             
              <svg  class="text-primary opacity-70 fill-primary w-4 h-4 stroke-primary" viewBox="0 0 482.1 482.1"  xml:space="preserve">
                <path d="M315.5,0H121.8C93.5,0,70.5,23,70.5,51.3v189.8h-5c-11.4,0-20.7,9.3-20.7,20.7v125.4c0,11.4,9.3,20.7,20.7,20.7h5v23   c0,28.3,23,51.3,51.3,51.3H386c28.3,0,51.3-23,51.3-51.3V121.4L315.5,0z M386,450.7H121.8c-11,0-19.9-8.9-19.9-19.9v-23h246.3   c11.4,0,20.7-9.2,20.7-20.7V261.7c0-11.4-9.3-20.7-20.7-20.7H101.9V51.3c0-10.9,8.9-19.9,19.9-19.9l181.9-0.2v67.2   c0,19.6,15.9,35.6,35.6,35.6l65.9-0.2l0.7,296.9C405.9,441.8,397,450.7,386,450.7z"/>
                <path d="M135,319.9c0.1-18.1-10.5-27.6-27.4-27.6c-4.4,0-7.2,0.4-8.9,0.8v56.9c1.7,0.4,4.4,0.4,6.8,0.4   C123.4,350.5,135,340.7,135,319.9z"/>
                <path d="M227.7,321c0-15.6-7.5-29-21-29c-13.3,0-21,12.7-21,29.5c0,17,8,29,21.2,29C220.1,350.6,227.7,337.9,227.7,321z"/>
              </svg>
              <span class="text-sm flex-1 truncate text-primary">
                {{ file.filename }}
              </span>
            </a>
          </div>

            <!-- <a class="p-2 text-sm text truncate" :title="file.filename" v-for="file in getValue" :key="file.link" :href="file.link" v-text="file.filename"></a> -->
       </div>
        <div class="flex items-center py-10 justify-center" v-else>
            <Empty :text="$t('components.formulate_library.no_files_found')" />
        </div>
  </div>
</template>

<script>
export default {
  props: {
    // },
    context: {
      type: Object,
      required: true,
    },
  },
  methods: {},

  computed: {
    model() {
        return this.context.model
    },
    getValue: {
        get() {
          return this.model || [];
        },
        set(val) {
          // this.$emit("change",);
          console.log("val :>> ", val);
        },
    },
    isDisabled() {
      return this.context?.attributes?.disabled ?? false
    }
  },

  mounted() {
    console.log("this.context :>> ", this.getValue);
  },
};
</script>

<style>
</style>